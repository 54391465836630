import { colors, ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#FAFAFA",
    paper: colors.common.white,
    gradient:
      "linear-gradient(90deg, rgba(29,53,134,1) 0%, rgba(123,103,246,1) 50%, rgba(0,238,145,1) 100%)",
  },
  primary: {
    main: "#7b67f6",
  },
  secondary: {
    main: "#00ee91",
    contrastText: "#1d3586",
  },
  success: {
    main: "#00ee91",
  },
  error: {
    main: "#ff0019",
  },
  action: {
    active: "#7b67f6",
  },
  text: {
    primary: "#1d3586",
    secondary: "#1d3586",
  },
};

export default palette;
